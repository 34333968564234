import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../common/accordion';

const Faq = ({ question, answer, isActive, toggleActive }) => {
  return (
    <div className="bg-old-navy-blue rounded-4xl py-6 pl-6 md:pl-12 pr-4 md:pr-6">
      <Accordion isActive={isActive} className="gap-x-8">
        <Accordion.Title>
          {/* eslint-disable-next-line */}
          <h2 className="SB24 md:SB32" onClick={toggleActive}>
            {question}
          </h2>
        </Accordion.Title>
        <Accordion.Toggle>
          <button
            type="button"
            aria-pressed={isActive}
            aria-label={isActive ? 'close' : 'open'}
            onClick={toggleActive}
            className="block flex-shrink-0 relative w-14 h-14 rounded-full bg-blue-bubble text-white shadow-glow"
          >
            <svg
              className="absolute center"
              role="img"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`transform origin-center transition-transform duration-300 ease-in-out ${
                  isActive ? 'rotate-270' : 'rotate-0'
                }`}
                d="M9.5 9.5L9.5 15C9.5 15.5523 9.05229 16 8.5 16H7.5C6.94772 16 6.5 15.5523 6.5 15L6.50001 9.5L6.50001 6.5L6.50001 1C6.50001 0.447715 6.94773 0 7.50001 9.53674e-07H8.50001C9.0523 1.90735e-06 9.50001 0.447717 9.50001 1L9.50001 6.5L9.5 9.5Z"
                fill="currentColor"
              />
              <path
                className={`transform origin-center transition-transform duration-300 ease-in-out ${
                  isActive ? 'rotate-180' : 'rotate-0'
                }`}
                d="M6.5 9.5L0.999999 9.5C0.447715 9.5 0 9.05229 0 8.5V7.5C0 6.94772 0.447716 6.5 1 6.5L6.5 6.50001L9.5 6.50001L15 6.50001C15.5523 6.50001 16 6.94773 16 7.50001V8.50001C16 9.0523 15.5523 9.50001 15 9.50001L9.5 9.50001L6.5 9.5Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </Accordion.Toggle>
        <Accordion.Content>
          <div
            className="text-prose py-4 md:py-6"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

Faq.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  isActive: PropTypes.bool,
  toggleActive: PropTypes.func,
};

export default Faq;
