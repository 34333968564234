import React, { useCallback, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';

import Faq from './faq';
import slugify from '../../helpers/slugify';

const Faqs = ({
  faqs,
  currentAccordion,
  setCurrentAccordion,
  getPreviousPlatform,
  getCurrentPlatform,
  getNextPlatform,
  transitionStatus,
  onTransitionComplete,
}) => {
  // This is getting ugly
  const initialGetPreviousPlatform = useCallback(getPreviousPlatform, []);

  const wrapperRef = useRef();

  const getTimeline = (dir) => {
    const tl = gsap.timeline({ paused: true });
    tl.fromTo(
      wrapperRef.current,
      { x: 100 * dir, opacity: 0 },
      { x: 0, opacity: 1 }
    );
    return tl;
  };

  useEffect(() => {
    let tl;
    if (transitionStatus == 'entering') {
      tl = getTimeline(getCurrentPlatform() - initialGetPreviousPlatform());
      tl.play();
    } else if (transitionStatus == 'exiting') {
      tl = getTimeline(getCurrentPlatform() - getNextPlatform());
      tl.seek(tl.endTime()).reverse();
    }
    if (tl) {
      tl.then(onTransitionComplete);
      return () => tl.kill();
    }
  }, [transitionStatus]);

  return (
    <ul className="space-y-6 mb-8 sm:mb-12 xl:mb-16 opacity-0" ref={wrapperRef}>
      {faqs.map(({ question, answer }) => {
        const id = slugify(question);
        return (
          <li key={id}>
            <Faq
              question={question}
              answer={answer}
              isActive={currentAccordion == id}
              toggleActive={() =>
                setCurrentAccordion(currentAccordion == id ? -1 : id)
              }
            />
          </li>
        );
      })}
    </ul>
  );
};

Faqs.propTypes = {
  faqs: PropTypes.array,
  currentAccordion: PropTypes.string,
  setCurrentAccordion: PropTypes.func,
  getPreviousPlatform: PropTypes.func,
  getCurrentPlatform: PropTypes.func,
  getNextPlatform: PropTypes.func,
  transitionStatus: PropTypes.oneOf(['waiting', 'entering', 'exiting']),
  onTransitionComplete: PropTypes.func,
};

export default Faqs;
