/* eslint-disable react/display-name */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const Accordion = ({
  isActive,
  children,
  className = '',
  style = {},
  ...rest
}) => {
  return (
    <article
      className={`grid ${className}`}
      style={{
        gridTemplateColumns: 'auto 1fr',
        gridTemplateAreas: '"title toggle" "content content"',
        ...style,
      }}
      {...rest}
    >
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, { isActive, key: index })
      )}
    </article>
  );
};

Accordion.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

Accordion.Title = ({ children }) => {
  return <header style={{ gridArea: 'title' }}>{children}</header>;
};

Accordion.Title.propTypes = {
  children: PropTypes.node,
};

Accordion.Toggle = ({ children }) => {
  return (
    <div
      className="justify-self-end leading-none"
      style={{ gridArea: 'toggle' }}
    >
      {children}
    </div>
  );
};

Accordion.Toggle.propTypes = {
  children: PropTypes.node,
};

Accordion.Content = ({ isActive, children }) => {
  const ref = useRef();
  const getComputedHeight = (ref) => {
    if (ref.current) {
      return window.getComputedStyle(ref.current).height;
    }
    return '100%';
  };

  return (
    <div
      className={`accordion ${
        isActive ? 'accordion--open' : ''
      } transition-height duration-300 ease-in-out`}
      style={{ '--height': getComputedHeight(ref) }}
    >
      <div
        className={`${
          isActive ? 'opacity-100' : 'opacity-0'
        } transition-opacity duration-300 ease-in-out`}
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.Content.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
};

export default Accordion;
