import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from '@reach/router';
import { graphql, navigate, useStaticQuery } from 'gatsby';
// import PropTypes from 'prop-types';

import Layout from '../layout/layout';
import SEO from '../meta/seo';
import Faqs from './faqs';
import EntryExitScheduler from '../animations/entryExitScheduler';
import useValueReference from '../../hooks/use-value-reference';

import ArrowRight from '../../assets/arrow-right.svg';

const Support = () => {
  const [currentAccordion, setCurrentAccordion] = useState('');
  const { appleFaqs, webFaqs } = useStaticQuery(graphql`
    {
      appleFaqs: allTicketCounterFaq(
        filter: { app: { id: { eq: "409838725" } }, lang: { eq: "en" } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            question: title
            answer: bodyHtml
          }
        }
      }
      webFaqs: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "//faqs//" }
          frontmatter: { platform: { eq: "web" } }
        }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              question
            }
            answer: html
          }
        }
      }
    }
  `);

  const platformsOrder = ['apple', 'web'];
  const platformsData = {
    apple: {
      label: 'Apple',
      // Reduce the number of levels
      faqs: appleFaqs.edges.map(({ node }) => node),
    },
    web: {
      label: 'Web',
      // Reduce the number of levels and flatten the structure
      faqs: webFaqs.edges.map(({ node }) => {
        const { frontmatter, ...rest } = node;
        return {
          ...frontmatter,
          ...rest,
        };
      }),
    },
  };

  const location = useLocation();

  const getLocationInfo = useCallback(() => {
    const hash = location.hash.slice(1);

    const normalizePlatform = (platform) => {
      if (platformsOrder.indexOf(platform) != -1) {
        return platform;
      }
      return platformsOrder[0];
    };

    return normalizePlatform(hash);
  }, [location]);

  const [currentPlatform, setCurrentPlatform] = useState(platformsOrder[0]);

  useEffect(() => {
    setCurrentPlatform(getLocationInfo());
    setCurrentAccordion('');
  }, [getLocationInfo]);

  const getCurrentPlatform = useValueReference(currentPlatform);

  const [previousPlatform, setPreviousPlatform] = useState('');

  useEffect(() => {
    setPreviousPlatform(currentPlatform);
  }, [currentPlatform]);

  return (
    <Layout>
      <SEO
        title="Support and Help Center"
        description="Ask us anything! Welcome to our frequently asked questions page of Splice. Read the FAQ and get the answers about our product and service."
      />

      <div className="container space-around">
        <h1 className="text-title max-w-content mx-auto text-center mb-6 sm:mb-8 xl:mb-12">
          Frequently Asked Questions.
        </h1>

        <div className="flex flex-col md:flex-row justify-end items-center mb-6 sm:mb-8 xl:mb-12">
          <h2 className="text-center contrast-low mb-4 md:mb-0 md:mr-4">
            Where did you get your subscription?
          </h2>
          <div>
            {platformsOrder.map((key) => {
              const { label } = platformsData[key];
              const isPressed = key == currentPlatform;
              return (
                <button
                  className={`btn text-white py-3 px-6 first:pr-4 last:pl-4 rounded-none first:rounded-l-4xl last:rounded-r-4xl border-transparent ${
                    isPressed
                      ? 'bg-blue-bubble-light'
                      : 'bg-old-navy-blue-light'
                  }`}
                  type="button"
                  onClick={() => {
                    navigate(`#${key}`);
                  }}
                  aria-pressed={isPressed}
                  key={key}
                >
                  {label}
                </button>
              );
            })}
          </div>
        </div>

        <EntryExitScheduler>
          <Faqs
            faqs={platformsData[currentPlatform].faqs}
            getPreviousPlatform={() => platformsOrder.indexOf(previousPlatform)}
            getCurrentPlatform={() => platformsOrder.indexOf(currentPlatform)}
            getNextPlatform={() => platformsOrder.indexOf(getCurrentPlatform())}
            currentAccordion={currentAccordion.split('-').slice(1).join('-')}
            setCurrentAccordion={(id) =>
              setCurrentAccordion(`${currentPlatform}-${id}`)
            }
            key={currentPlatform}
          />
        </EntryExitScheduler>

        <div className="text-center">
          <h3 className="B24 md:B32 mb-4 sm:mb-6">Need further help?</h3>
          <a
            className="btn btn--blue"
            href="https://support.spliceapp.com/hc/en-gb/requests/new"
          >
            <div>Contact us</div>
            <ArrowRight />
          </a>
        </div>
      </div>
    </Layout>
  );
};

Support.propTypes = {};

export default Support;
